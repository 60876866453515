.interaction-component {
  padding: 20px 20px 0 20px;
  overflow-x: auto;
  height: 250px;
  overflow-x: auto;
  /* background-color: aquamarine; */
  width: 100%;
}
.interaction-add-component {
  width: 100%;
}
.TimelineHorizontalWrapper-sc-1dd6qhe-0 {
  /*  height: 50px; */
  padding: 0px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  height: 80%;
}

.TimelineItemWrapper-sc-1dd6qhe-1.hDYzXK.visible.timeline-horz-item-container {
  width: 200px;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  flex-direction: column;
}

/* Style the VerticalTimelineElement */
.VerticalTimelineElement {
  background-color: blue; /* Set background color to blue */
  color: white; /* Set text color to white */
}

/* Adjust positioning of the floating action button */
.fixed-action-button {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20; /* Ensure it's above other content */
}

.top-to-btm {
  /* position: absolute; */
  display: flex;
  float: right;
  width: 100%;
  justify-content: flex-end;
  /* bottom: 20px; Adjust as needed */
  /* right: 20px; */
}
.icon-positioned {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8); /* Adjust opacity as needed */
  /* padding: 5px; */
  /* position: relative; */
  /* margin-right: 20px; */
  /* margin-bottom: 20px; */
  transition: background-color 0.3s ease;
}

.icon-positioned:hover {
  background-color: rgba(255, 255, 255, 1); /* Adjust opacity as needed */
}

.icon-styles {
  cursor: pointer;
}

.interaction-form {
  padding: 0 40px 30px 40px;
  width: 100%;
}
.dBAArN {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.25rem;
  min-height: 25vh;
  background-color: var(--app-bar-color);
  color: var(--font-color);
  outline: 0;
}
.save-btn-style {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}
h5 {
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
}
.loan_number_style {
  margin: 0 auto;
  font-weight: 700;
  text-align: center;
}
.cus_style {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  /* text-align: center; */
}
.through_SMS {
  background-color: rgb(127, 210, 255);
}
.through_calls {
  background-color: rgb(98, 226, 226);
}
.through_mails {
  background-color: rgb(105, 178, 255);
}
.time_icon_style {
  background-color: #4476ff;
}
.vertical_time_line_ele {
  /* background-color: aquamarine; */
  padding: 10px;
}
.date_style {
  font-weight: 700;
}

.TimelineItemContentWrapper-sc-d7qjm1-0.hGPNtI.timeline-card-content.rc-card {
  display: flex;
  justify-content: center;
  background-color: #639bf0;
}
.follow-text-style {
  background-color: var(--app-bar-color);
  color: var(--header-font-color);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: larger;
}
.chorno-style {
  background-color: #639bf0;
}
.TimelineVerticalWrapper-sc-1427v1d-0.hzItGr {
  background-color: var(--app-bar-color);
}
.add_remarks {
  width: 100%;
  margin-bottom: 10px;
  background-color: var(--app-bar-color);
  color: var(--header-font-color);
}
.radio_response {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
