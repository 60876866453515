:root {
  --commonpaper-background: #ffffff;
  --header-font-color: #ffffff;
  --font-color: #000000;
  --font-family: Arial, sans-serif;
  --disabled-field: #ffffff65;
  --font-size: 18px;
  --roundness: 20px;
  --disabled-font: #616161;
  --menu-color-1: #c2ddfb;
  --menu-color-2: #f3c0af;
  --dark-theme-color: #282829;
  --light-theme-color: #ffffff;
  /* --app-bar-color: #3c5d90; */
  --app-bar-color: #3c5d90;
  --text-field: #eaeaea;
  --table-even-row: #e9e9e9;
  --back-parent-color: #e5ebed;
  --add-loan-header: #d7e0ee;
  --font-weight: 700;
  --approved: #eaffea;
  --pending: #fffaea;
  --rejected: #f9918f;
  --closed: #eaeaff;
  --disposed: #5b7cf5;
  --table-hover: #f8f8f8;
  --dashboard-paper: #d7e0ee;
  --auction-row: #fde9e9;
  --scheme-expiry-row: #fbedb6;
  --dashboard-border: #fff;
  --common-font-size: 22px;
  --positive-color: green;
  --negative-color: red;
}

[data-theme="dark"] {
  --commonpaper-background: var(--dark-theme-color);
  --approved: #459845;
  --pending: #9e8226;
  --disabled-field: #2d2d2d34;
  --rejected: #c04e4c;
  --closed: #7070e3;
  --disposed: #5778f0;
  --table-even-row: #4f4f4f;
  --table-odd-row: #303030;
  --add-loan-header: #435e8e;
  --back-parent-color: #303030;
  --text-field: #4f4f4f;
  --table-hover: #777777;
  --dashboard-paper: var(--dark-theme-color);
  --auction-row: #ad5d5d;
  --scheme-expiry-row: #af7342;
  --positive-color: rgb(83, 222, 83);
  --negative-color: rgb(255, 59, 59);
}
