.color-container {
  width: 70%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: -19px;
}
.color-container:active {
  border: var(--dashboard-border);
}
.color-container:focus {
  border: var(--dashboard-border);
}

body[data-theme="light"] {
  color: black;
  background-color: white; /* Assuming a white background for light theme */
}

body[data-theme="dark"] {
  color: white;
  background-color: black; /* Assuming a black background for dark theme */
}
.color-circle-default {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  margin: 10px;
  cursor: pointer;
  background-color: #3c5d90;
  position: relative; /* Required for positioning the pseudo-element */
  outline: 2.5px solid rgb(63, 62, 62); /* Thick outline with 50% opacity */
}

.color-circle-default::before {
  content: "";
  position: absolute;
  top: 45%; /* Center vertically */
  left: 0;
  right: 0;
  height: 5px; /* Thickness of the line */
  background-color: rgb(63, 62, 62); /* White color with 60% opacity */
  transform: rotate(-45deg); /* Angle the line */
}
.color-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: end;
  align-items: end;
  margin: 10px;
  cursor: pointer;
  background-color: #5c4300;
}
.color-picker {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.brown {
  background-color: #eeebe2;
}
.red {
  background-color: #ffcdd2;
}
.blue {
  background-color: #3c5d90;
}
.blue2 {
  background-color: #0e3b82;
}
.gray {
  background-color: #DBDBDB;
}
.purple {
  background-color: #550737;
}
.indigo {
  background-color: #464d66;
}
.black {
  background-color: #0d1117;
}
.green {
  background-color: #b2d2c8;
}
