.branch-form {
  padding: 0 40px 30px 40px;
}
.save_button_ {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.hint-prefix-text {
  font-size: 12px;
  color: rgb(0, 102, 255);
}
.divider-tenant {
  color: var(--app-bar-color);
}
.top-icons-style {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
