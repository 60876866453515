.custom-pagination .MuiTablePagination-menuIcon {
  display: "hidden";
}

.pop_over_style {
  padding: 10px;
  border-radius: 5px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button_style {
  display: flex;
  justify-content: center;
}
.context-menu {
  width: 250px;
  gap: 10px;
  padding: 10px;
  background-color: var(--commonpaper-background) !important;
  color: var(--font-color);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
