.auctioned-button {
  min-width: auto;
  display: flex;
  justify-content: end;
  background-color: rgb(255, 64, 64);
  color: black;
  float: inline-end;
  font-weight: 900;
  text-transform: capitalize;
}
.auctioned-button:hover {
  transform: scale(1);
}
.active-loan-btn {
  min-width: auto;
  display: flex;
  justify-content: flex-end;
  background-color: #b1f164;
  color: black;
  float: inline-end;
  font-weight: 900;
  text-transform: capitalize;
}
.auction_button_style {
  background-color: rgb(255, 96, 96);
  color: black;
}
.button_class_style:hover {
  color: black;
}
