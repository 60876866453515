.common_button {
  display: flex;
  justify-content: start;
  align-items: end;
}
.divider_class {
  margin: 10px 0 10px 0;
}

.container_class {
  /* margin-top: 10px; */
  margin-bottom: 20px;
  display: flex;
  align-items: end;
}
.grid_item {
  display: flex;
  justify-content: center;
  align-items: end;
}
.text_align_class {
  display: flex;
  align-items: end;
}
.grid_table_container {
  margin-top: 20px;
}
