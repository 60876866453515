.container-wrapper {
  border-radius: 25px !important;
  padding: 5px 10px 10px 10px;
  color: var(--font-color) !important;
  background-color: var(--commonpaper-background) !important;
  border: 2px solid var(--dashboard-border) !important;
}

.container-wrapper-auctioned {
  border-radius: 25px !important;
  border: 2px solid var(--dashboard-border) !important;
  padding: 5px 10px 10px 10px;
  cursor: pointer;
  color: var(--font-color) !important;
  background-color: var(--commonpaper-background) !important;
  transition: all ease 1s;
}

.container-wrapper-auctioned:hover {
  transform: scale(1.1);
}

.title {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title p {
  font-weight: 700;
}

.detail {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5px;
}

.detail .icon {
  font-size: 50px;
}

.detail .count {
  font-size: 25px;
}

.detail .percentage {
  padding: 2px 10px;
  margin-bottom: 8px;
  font-size: 12px;
  border-radius: 20px;
}
.man_detail {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
