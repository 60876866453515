.dateContainer {
  display: flex;
}

.dateContainer .dateContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.dateContainer .range_picker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}
