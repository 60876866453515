.table-input-field {
  border-radius: 5px;
  padding: 5px;
  outline: none;
  width: 30%;
  transition: transform 1s ease-in-out;
  border: none;
  margin-right: 10px;
  box-shadow: 2px 2px black;
}

.table-input-field:hover {
  transform: scale(1.1);
  /* box-shadow:2px 2px  black; */
  /* margin-right: 15px; */
}

.table-input-field:focus {
  transform: scale(1.1);
  margin-right: 15px;
  /* box-shadow:2px 2px  black; */
}

.edit-buttons {
  display: flex;
  justify-content: center;
  margin-left: -50px;
  /* Adjust as needed based on the button width */
}

.edit-buttons:hover {
  margin-left: 0;
}
.legend_style {
  font-size: 12px;
  font-family: var(--font-family);
  color: var(--font-color);
}
