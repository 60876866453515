.total_collected_container {
  display: flex;
  justify-content: space-evenly;
}
.amount_wrapper {
  border-radius: 20px !important;
  padding: 5px 10px 10px 10px !important;
  height: 100% !important;
  display: flex;
  color: var(--font-color) !important;
  background-color: var(--commonpaper-background) !important;
  border: 2px solid var(--dashboard-border) !important;
  flex-direction: column;
  align-content: center;
}
.today_amount_wrapper {
  border-radius: 20px !important;
  border: 2px solid var(--dashboard-border) !important;
  padding: 5px 2px 10px 10px !important;
  display: flex;
  justify-content: space-between;
}
.vertical_text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
  text-align: center;
}

.man_container-wrapper {
  border-radius: 20px !important;
  padding: 5px;
  padding-bottom: 0;
}
.man_detail {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.man_detail .icon {
  font-size: 50px;
}

.man_count {
  font-size: 10px;
}
.bottom_containers_style {
  width: 62% !important;
}
.bottom_table_style {
  margin-left: 30px;
  width: 40% !important;
}
.item_rate_silver {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px !important;
  padding: 17px !important;
  background-image: linear-gradient(
    to bottom,
    #c0c0c0,
    #d0d0d0,
    #dbdbdb,
    #e3e3e3,
    #d6e0ed
  );
}

.item_names_rates {
  display: flex;
  /* justify-content: space-around; */
}
.man_item_names_rates {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.item_rate_type {
  display: flex;
  justify-content: space-between;
}
.item_rate_type .silver {
  color: #848482;
}
.item_rate_type .gold {
  color: #a38a00;
}
.item_rate_gold {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px !important;
  padding: 20px !important;
  background-image: linear-gradient(
    to bottom,
    #ffd700,
    #ffe030,
    #fbec93,
    #fbf5d3,
    #d7e3d9
  );
}
