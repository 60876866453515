:root {
  --primary: #fde9e9;
  --secondary: #fbedb6;
  --ternary: #7fff83;
}
footer {
  font-size: var(--font-size);
}
body {
  /* font-family: var(--font-family); */
  font-size: var(--font-size) !important;
  background-color: var(--commonpaper-background) !important;
}
.commonpaper {
  background-color: var(--commonpaper-background) !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  color: var(--font-color) !important;
  min-height: 80vh;
  max-width: 94vw;
  padding: 20px;
}
.grid-header {
  background-color: var(--app-bar);
  color: "#ffffff";
}

.common-font-style {
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  /* color: var(--font-color) !important; */
}
/* Add media queries for responsiveness */
@media screen and (max-width: 768px) and (min-width: 991px) {
  .commonpaper {
    width: 78vw; /* Adjust max-width as needed */
  }
}

@media (max-width: 992px) {
  .commonpaper {
    width: 90vw; /* Default padding for larger screens */
  }
}
/* Add more media queries as needed for different screen sizes */

.even-row-class {
  background-color: #a1a1a1;
}
::-webkit-scrollbar-track {
  border: 5px solid ivory;
  background-clip: padding-box;
  cursor: pointer;
  background-color: #b2bec3;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #fffff0;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  cursor: pointer;
  border-radius: 10px;
}

.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
  text-align: center;
  margin-left: 30px; 
}

.welcome-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.error-text {
  color: red;
  font-size: medium;
}

/*logo section*/

.logo {
  color: white;
}

.logo_1 {
  font-size: 48px;
  font-weight: bolder;
}

.logo_2 {
  font-size: 20px;
}

/*Nav section*/
#nav_items button {
  color: white;
  margin-right: 60px;
  font-weight: 600;
  border: none;
  background: none;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.5s ease;
}

#nav_items button:hover {
  border-bottom: 8px solid rgb(255, 255, 255);
}

/*login button*/
.login_btn {
  border: none;
  background-color: white;
  box-shadow: "5px 20px 30px black";
  color: black;
  font-weight: 600;
  padding: 6px 30px;
  border-radius: 10px;
}

.login_btn:hover {
  transform: scale(1.1);
}
/*home Content*/

#Home_Div {
  height: 100vh;
  background: rgb(5, 60, 183);
  background: linear-gradient(
    37deg,
    rgba(5, 60, 183, 1) 33%,
    rgba(0, 212, 255, 1) 100%
  );
  animation: pulse 4s infinite;
  padding-top: 80px;
  text-align: justify;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow-y: hidden;
}

#Home_Div .para_content-1 {
  font-weight: bold;
  font-size: 65px;
  line-height: 65px;
  color: white;
}

.trans_btn {
  background-color: rgba(240, 240, 240, 0.3);
  border: none;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  backdrop-filter: blur(5px);
  border-radius: 20px;
}

.fixed-top {
  transition: transform 0.3s ease;
}

.scrolled {
  transform: translateY(-100%);
}

.innerDiv_3 {
  padding-top: 20%;
}

#About_Div {
  height: 100vh;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.innerDivision_1 {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10%;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.3)
    ),
    url("../public/aboutBackground.jpg");
  background-attachment: fixed;
  animation: backgroundAnimation 10s infinite linear;
}

@keyframes backgroundAnimation {
  from {
    background-position: 0% 0%;
  }

  to {
    background-position: 100% 100%;
  }
}

.pro_h1 {
  text-align: center;
}

.ser_h1 {
  font-weight: 600;
  letter-spacing: 15px;
  word-spacing: -12px;
  text-align: center;
}

.innerDiv_1 p {
  text-align: center;
  font-weight: 300;
}

.innerDiv_2 {
  display: flex;
  justify-content: center;
  align-items: self-start;
  flex-direction: column;
}

.innerDivision_2 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.page_h1 {
  font-weight: 600;
  letter-spacing: 15px;
  word-spacing: -12px;
}

/* .innerDiv_2 h2 {
  /* font-family: 'Glass Antiqua', cursive; *

} */

.innerDiv_2 section {
  text-align: center;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#Pages_Div {
  height: 100vh;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0e68c;
  overflow-x: hidden;
}

/* .innerDiv_2 section p {
  /* font-family: 'Roboto', sans-serif; *
} */

/* .page_para {
  /* font-family: 'Roboto', sans-serif; *

} */

#schemes {
  height: 100vh;
  background-color: #febe10;
  padding-top: 80px;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.cardHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card_ {
  border: 1px solid white;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  max-width: 300px;
}

.scheme_inner1 {
  text-align: center;
}

.scheme_h1 {
  font-weight: 600;
  letter-spacing: 15px;
  word-spacing: -12px;
}

/* .scheme_para {
  /* font-family: 'Roboto', sans-serif; *
} */

#Portfolio_Div {
  height: 100vh;
  color: white;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.7),
      rgba(0, 0, 0, 0.3)
    ),
    url("../public/wall2.png");
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.port_h1 {
  font-weight: 500;
}

/* .para_port,
.para_con {
  /* font-family: 'Roboto', sans-serif; *

}

.head_map {
  /* font-family: 'Kalam', cursive; *
} */

#contactUs {
  height: 100vh;
  color: white;
  background-color: rgb(253, 210, 99);
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.contact_details {
  padding: 20px;
  text-align: center;
  color: black;
}

.contact_h1 {
  font-weight: 600;
  letter-spacing: 15px;
  word-spacing: -12px;
}

li .fraudulant-list-style {
  color: red;
  font-weight: 700;
}
.auction_button_style {
  text-transform: capitalize;
  font-weight: 800;
}
.scheme_loan_row {
  background-color: var(--scheme-expiry-row);
}
.auctioned_loan_row {
  background-color: var(--auction-row);
}

.my-custom-switch {
  .ant-switch-checked {
    background-color: #f50057;
    border-color: #f50057;
  }

  &:hover .ant-switch-checked {
    background-color: #e0004d;
  }

  .ant-switch-handle {
    border-color: #f50057;
  }

  &:hover .ant-switch-handle {
    border-color: #e0004d;
  }
}
.autoscroll-horizontal {
  white-space: nowrap;
  color: var(--negative-color);
  overflow: hidden;
  width: 100%;
}

.autoscroll-horizontal p {
  display: inline-block;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
