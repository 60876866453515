.help-component {
  padding: 10px;
  margin-top: 20px;
}
.help-container {
  overflow: auto;
  height: 75vh;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}
.help-container h2,
.help-container h1 {
  margin-bottom: 10px;
  color: var(--app-bar-color);
  font-weight: 700;
  display: flex;
}

.help-container li {
  padding: 0;
}
.help-container ul {
  padding: 10px;
  margin-left: 100px;
}
