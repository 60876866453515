.chart {
  height: 300px !important;
  font-size: var(--font-size);
  /* background-color: var(--commonpaper-background) !important; */
}
/* MyChart.css */
.apexcharts-canvas {
  /* border-radius: 15px; */
  /* Set the border radius here */
  border-radius: 25px !important;
  font-size: var(--font-size);
  overflow: hidden;
  /* Ensure content is clipped to the border-radius */
  /* height: 100% !important; */
}

.char-container {
  border-radius: 25px !important;
  font-size: var(--font-size);
  /* color: var(--font-color) !important; */
  border: 2px solid var(--dashboard-border) !important;
  background-color: var(--commonpaper-background) !important;
}
.fundStyle {
  padding: 10px;
  margin-bottom: 12px;
  font-size: var(--font-size);
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.title_style {
  text-align: left;
  margin: 10;
  offset: 10;
  /* margin-top: -20px; */
  /* font-weight: bold; */
  color: #263238;
  width: 50%;
}
