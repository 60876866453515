.language-preference {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
  margin: 50px;
}
.general-comp {
  /* padding: 0 50px; */
  min-height: 72vh;
  /* border: 2px var(--font-color) solid; */
}
.add-item-comp {
  min-height: 45vh;
  max-height: 75vh;
}

.general_titles{
  
}
h1 {
  margin-bottom: 10px;
  color: var(--app-bar-color);
  font-weight: 700;
  display: flex;
}

.settings-comp {
  background-color: var(--commonpaper-background) !important;
  color: var(--font-color) !important;
  height: 72vh;
  padding-right: 10px;
  overflow-y: auto;
  max-width: 75vw;
}
.themePreferenceComp {
  display: flex;
  justify-content: space-between;
  height: 30px;
}
.sidePanle {
  display: flex;
  height: 30px;
}
.sidePanelName {
  padding-top: 20px;
}
.sidePanelNameAlign {
  margin-left: 1167px;
  padding-top: 20px;
}
.fontSize {
  display: flex;
  justify-content: space-between;
  height: 30px;
}
.FontSizeAlign {
  width: 50%;
}
.customFont {
  display: flex;
  justify-content: space-between;
  height: 30px;
}
.customFontName {
  padding-top: 50px;
}
.customFontAlign {
  padding-top: 50px;
  width: 50%;
}
/* .themes_name {
  margin: 0 auto;
} */
.light-round {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  border: 1px solid black;
}

.dark-round {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--dark-theme-color);
  cursor: pointer;
  border: 1px solid black;
}

.themePreferenceComp .themes {
  display: flex;
}
