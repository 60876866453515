@import 'https://fonts.googleapis.com/css?family=Tangerine|Inconsolata|Droid+Sans';
@import 'https://fonts.googleapis.com/css?family=Quicksand&display=swap';

.whole-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
.image{
	width: 90%;
	font-weight: 400;
	height: 44px;
    padding: 4px;
	user-select: none;
	/* text-decoration:line-through; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
	border: rgb(0, 0, 0) 2px solid;
}
.whole-container{
    display: flex;
    flex-direction: row;
}

.icon{
    color: white;
    cursor: pointer;
}

/* .icon:hover{
    transform: rotateZ(90deg);
    color: rgb(0, 94, 255);
} */