.dashboard-wrapper {
  background: var(--dashboard-paper) !important;
  color: var(--font-color) !important;
  width: 100%;
  min-height: 90vh;
  border-radius: 30px !important;
  padding: 15px 20px;
}

.formControl {
  width: 50%;
}

.date-filter-container {
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-content .header {
  background-image: linear-gradient(
    to bottom,
    #c0c0c0,
    #d0d0d0,
    #dbdbdb,
    #e3e3e3,
    #d6e0ed
  );
  border-radius: 20px !important;
  padding: 5px;
}

.header-content .last-update {
  text-align: center;
}

.header-content .header-item {
  /* background-color: red; */
  font-size: 18px;

  display: flex;
  gap: 20px;
  /* height: 50%; */
  /* grid-template-columns: auto auto auto; */
}

.header-item .price {
  display: flex;
  justify-content: center;
  height: 70px;
}

.header-item .price p {
  display: flex;
  justify-content: center;
  font-size: 19px;
  font-weight: 500;
  margin-top: 25%;
  padding-top: 10px;
}

.header-content:first-child .header {
  padding-left: 20px;
}

.header-content:last-child .header {
  background-image: linear-gradient(
    to bottom,
    #ffd700,
    #ffe030,
    #fbeb9389,
    #fbf5d357,
    #d7e3d916
  );
}
.man_detail {
  display: flex;
  height: 100% !important;
  justify-content: center;
  align-items: center;
}

/* .header-content:last-child .header-item div:first-child {
    display: flex;
    align-items: center;
} */

.man_dashboard-wrapper {
  background: var(--dashboard-paper) !important;
  color: var(--font-color) !important;
  width: 100%;
  min-height: 84vh;
  border-radius: 20px !important;
  padding: 15px 20px;
}

.head_container_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 20px;
}

.rate_containers {
  display: flex;
  flex-direction: row;
}
.head_text {
  width: 20%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.auction_table_container {
  /* min-height: 40%;
  max-height: 100% !important; */
  color: var(--font-color) !important;
  background-color: var(--commonpaper-background) !important;
  border-radius: 25px !important;
  border: 2px solid var(--dashboard-border) !important;
  padding: 5px 20px !important;
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .header-item .price p {
    font-size: 20px;
  }
  .head_container_content {
    flex-direction: row;
  }
}

@media screen and (max-width: 900px) {
  .header-item .price p {
    font-size: 20px;
  }

  .formControl {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .formControl {
    width: 100%;
  }

  .header-item .price p {
    font-size: 22px;
  }

  .dashboard-wrapper {
    border-radius: 20px !important;
    padding: 20px;
  }
  .head_container_content {
    flex-direction: column;
  }
  .head_text {
    width: 100%;
  }
}
.item_rate_silver {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 14vh !important;
  border-radius: 20px !important;
  padding: 17px !important;
  background-image: linear-gradient(
    to bottom,
    #c0c0c0,
    #d0d0d0,
    #dbdbdb,
    #e3e3e3,
    #d6e0ed
  );
}
.item_names_rates {
  display: flex;
  justify-content: space-between;
}

.item_rate_type-tm .gold {
  font-size: var(--font-size);;
  width:150px;
  color: #a38a00;
  /* other styles */
}

.item_rate_type-tm .silver {
  font-size: var(--font-size);
  color: #848482;
  /* other styles */
}

.item_rate_type-en .gold {
  font-size: var(--font-size);
  color:  #a38a00;
  /* other styles */
}

.item_rate_type-en .silver {
  font-size: var(--font-size);
  color: #848482;
  /* other styles */
}

.item_rate_type-en .silvervalue {
  font-size: var(--font-size);
  font-family:var(--font-family) !important;
  color:#848482;
  /* other styles */
}

.item_rate_type-en .goldvalue {
  font-size: var(--font-size);
  font-family: var(--font-family) !important;
  color: #848482;
  /* other styles */
}

.item_rate_type-tm .silvervalue {
  font-size: var(--font-size);
  font-family:var(--font-family) !important;
  color:#848482;
  /* other styles */
}

.item_rate_type-tm .goldvalue {
  font-size: var(--font-size);
  font-family: var(--font-family) !important;
  color: #848482;
  /* other styles */
}
.item_rate_gold {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px !important;
  padding: 5px 20px !important;
  height: 14vh !important;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 215, 0, 0.7),
    rgba(255, 224, 48, 0.7),
    rgba(251, 236, 147, 0.7),
    rgba(251, 245, 211, 0.7),
    rgba(215, 227, 217, 0.7)
  );
}
