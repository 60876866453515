.add-loan-wrapper {
  width: 100%;
  min-height: 83vh;
  /* border-radius: 10px !important; */
  padding: 20px;
  background-color: var(--commonpaper-background) !important;
}

.add-loan-wrapper.active {
  pointer-events: none;
}

.add-btn.error {
  background: #ff8a80 !important;
  color: #fff !important;
  outline: none !important;
  border: none !important;
}

.add-btn.error:hover {
  background: #d32f2f !important;
  color: #fff !important;
  outline: none !important;
  border: none !important;
}

.add-btn,
.add-btn:hover,
.add-btn:focus {
  background: #b1f164 !important;
  color: #000 !important;
  outline: none;
  border: none !important;
  /* padding: 4px 10px; */
  /* font-weight: 700; */
}

.add-btn-container {
  display: flex;
  justify-content: flex-end;
}

.add-loan-header.Approved {
  background: var(--approved) !important;
}

.add-loan-header.Pending {
  background: var(--pending) !important;
}

.add-loan-header.Rejected {
  background: var(--rejected) !important;
}

.add-loan-header.Closed {
  background: var(--closed) !important;
}
.add-loan-header.Disposed {
  background: var(--disposed) !important;
}

.add-loan-header {
  background: var(--add-loan-header) !important;
  margin: 10px 0 20px 0;
  border: 1px solid #777;
  padding: 10px 10px 15px 10px;
  border-radius: 10px;
}

.add-loan-bottom {
  background: var(--add-loan-header) !important;
  margin: 20px 0 20px 0;
  border: 1px solid #777;
  padding: 10px 10px 15px 10px;
  border-radius: 10px;
}

.edit-loan-number {
  background: #eaffea !important;
  margin: 10px 0 20px 0;
  border: 1px solid #777;
  padding: 2px 10px;
  border-radius: 10px;
}
.add-loan-bottom.Approved {
  background: #eaffea !important;
}

.status {
  border-radius: 20px;
  padding: 2px 10px;
  font-weight: 700;
}

.status.Approved {
  background: var(--approved) !important;
}

.status.Pending {
  background: var(--pending) !important;
}

.status.Rejected {
  background: var(--rejected) !important;
}

.status.Closed {
  background: var(--closed) !important;
}

.status.Disposed {
  background: var(--disposed) !important;
}

.radio-btn-header {
  display: flex;
  gap: 20px;
}

.radio-btn-content {
  display: flex;
  align-items: center;
}

.appoveCell {
  width: 500px;
  position: relative;
}

.remarksText {
  position: absolute;
  top: 25%;
  right: 5px;
  width: 250px;
  border: none;
  outline: none;
  border-radius: 5px;
  /* left: -30px; */
  /* transform: translate(-83%, -50%); */
}

.remarksText.active {
  top: 50%;
  left: 0;
  transform: translate(10%, -50%);
  /* pointer-events: none; */
}
.rightClick {
  background-color: white;
  color: #030303;
  border: 1px solid #111111;
  border-radius: 5px;
  cursor: pointer;
  /* transition: background-color 0.3s; */
}

.rightClick:hover {
  background-color: #f0f0f0;
}

.contextMenuText {
  margin: 0;
  padding: 4px;
  display: flex;
  align-items: center;
}

.pop_over_style {
  padding: 10px;
  border-radius: 5px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: var(--font-size);
}

.button_style {
  display: flex;
  justify-content: center;
}
