.alignment {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.alignItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.rowAlign {
  display: flex;
  flex-direction: column;
}
.customer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.branch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.loan_number {
  padding: 0;
  margin: 0;
  font-weight: bold;
}
.loan_details {
  width: 100%;
}
.created_on {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.created_on p {
  font-weight: 100;
  font-size: small;
}
.popup {
  position: absolute;
  top: 10%;
  left: 75%;
  /* width: 260px; */
  padding: 10px 20px;
  background-color: white;
  box-shadow: none;
  border: none;
  border-radius: 5px;
}
.menu-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-family: var(--font-family);
}
.menu-list Typography {
  text-align: right;
}
